  /* .NewNote form textarea {
    height: 300px;
    font-size: 1.1rem;
  } */

  .ql-editor {
    box-sizing: border-box;
    line-height: 1.42;
    height: 300px;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  div.notesneworg {
    background-color: #EFEFF1;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.075) 0 0 2px inset,rgba(255, 255, 255, 0.8) 0 1px 1px;
    box-sizing: border-box;
    color: #2E2D33;
    font-family: Rubik,sans-serif;
    line-height: 22.85px;
    margin-top: 0px;
    overflow: hidden;
    padding-top: 18px;
    padding-bottom: 18px;
  }
  
  div.notesneworg:first-child {
    margin-top: 0;
  }

  div.info {
    box-sizing: border-box;
    color: #2E2D33;
    flex-grow: 1;
    font-family: Rubik, sans-serif;
    line-height: 22.85px;
    margin-right: 15px;
    overflow: hidden;
  }