.Home .lander {
    padding: 40px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
  }

  .Home .lander h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }

  .Home .lander p {
    color: #999;
  }

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: -10px;
  border-radius: .25rem;
}

.portal-link {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.section-header {
  box-sizing: border-box;
  color: #908C99;
  font-family: "Exo 2",sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.1;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 2px;
  text-transform: uppercase;
}