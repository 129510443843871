body {
  margin: 0;
  padding: 0;
  color: #333;
  background-color: #F9F9FA; 
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Open Sans", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}
input[type=file] {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.msteams {
  background-color: #69559E;
  border: 2px solid #514677;
  border-image: none 100% 1 0 stretch;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px 0;
  box-sizing: border-box;
  color: #FFFFFF;
  display: inline-block;
  font-family: "Exo 2", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 28.8px;
  padding: 14px 26px;
  text-align: center;
  text-decoration: none currentcolor solid;
  text-decoration-thickness: auto;
  text-transform: uppercase;
  transition: background-color .3s ease-out, border .3s ease-out;
}

.btn-msteams {
  background-color: #69559E;
  border: 2px solid #514677;
  border-image: none 100% 1 0 stretch;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px 0;
  box-sizing: border-box;
  color: #FFFFFF;
  display: inline-block;
  font-family: "Exo 2", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 28.8px;
  padding: 14px 26px;
  text-align: center;
  text-decoration: none currentcolor solid;
  text-decoration-thickness: auto;
  text-transform: uppercase;
  transition: background-color .3s ease-out, border .3s ease-out;
}

.btn-msteams:hover {
  background-color: #F98102;
}

.btn-msteams-sm {
  background-color: #69559E;
  border: 2px solid #514677;
  border-image: none 100% 1 0 stretch;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px 0;
  box-sizing: border-box;
  color: #FFFFFF;
  display: inline-block;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 20.8px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none currentcolor solid;
  text-decoration-thickness: auto;
  text-transform: uppercase;
  transition: background-color .3s ease-out, border .3s ease-out;
}

.btn-msteams-sm:hover {
  background-color: #F98102;
}



textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  border-color: #514677;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #514677;
  outline: 0 none;
}

.form-control:focus {
  border-color: #514677;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #514677;
  outline: 0 none;
}

a {
  color: #514677;
  text-decoration: none;
  background-color: transparent;
}

a:link {
  color: #514677;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #514677;
  text-decoration: none;
  background-color: transparent;
}

a.link {
  border-radius: 3px;
 color: #6A5CA2;
 cursor: pointer;
 display: inline-block;
 font-family: "Exo 2",sans-serif;
 font-size: 14px;
 line-height: 1;
 padding: 10px 17px 11px;
 text-decoration: none currentcolor solid;
 text-decoration-thickness: auto;
 transition: all .2s;
}


.btn-standard {
  background-color: #A29FAA;
  border: 1px solid #95919E;
  border-image: none 100% 1 0 stretch;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px 0;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Rubik,sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 27.2px;
  margin-top: 11px;
  padding: 4px 11px;
  text-decoration: none currentcolor solid;
  text-decoration-thickness: auto;
  text-transform: none;
  transition: background-color .3s ease-out,border .3s ease-out;
}


