/* .Home .lander {
    padding: 40px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
  }

  .Home .lander h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }

  .Home .lander p {
    color: #999;
  } */

/* .list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: -10px;
  border-radius: .25rem;
}

.portal-link {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
} */

/* Lander main */

main.lander-content {
  color: #2E2D33;
  font-family: Rubik,sans-serif;
  font-size: 17px;
  line-height: 27.2px;
}

/* div.header {
  background-color: #C7C6CC;
  background-image: linear-gradient(#F9F9FA 0,rgba(255,255,255,0) 90%,rgba(255,255,255,0) 100%),url();
  color: #2E2D33;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  line-height: 27.2px;
  text-align: center;
} */

/* a.announcement {
  background-color: #E3E3E7;
  border: 1px solid #9383BD;
  border-image: none 100% 1 0 stretch;
  border-radius: 3px;
  color: #6A5CA2;
  display: inline-block;
  font-family: Rubik, sans-serif;
  font-size: 15px;
  line-height: 24px;
  margin: 0 auto;
  padding: 4px 11px;
  text-align: center;
  text-decoration: none currentcolor solid;
  text-decoration-thickness: auto;
  transition: all .2s;
}

a.announcement:visited {
  color: #8276B1;
}

a.announcement:hover {
  background-color: #DBDAE0;
  border-color: #69559E;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px 0;
  color: #330033;
}

a.announcement:active {
  color: #5F5392;
} */


/* End Lander main */

/* CSS Custom Hero */

div.hero-container {
  color: #2E2D33;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  line-height: 27.2px;
  overflow: hidden;
  text-align: center;
}

div.hero {
  align-items: center;
  color: #2E2D33;
  display: flex;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  justify-content: flex-start;
  line-height: 27.2px;
  margin: 0 auto;
  max-width: 1280px;
  text-align: center;
}


div.copy {
  color: #2E2D33;
  flex: 0 0 50%;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  line-height: 27.2px;
  padding-left: 30px;
  text-align: center;
}

h1 {
  color: #2E2D33;
  font-family: Rubik, sans-serif;
  font-size: 64px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 1.2;
  margin: 0 0 10px;
  max-width: 450px;
  padding: 45px 0 0;
  text-align: left;
}


h2 {
  color: #2E2D33;
  font-family: Rubik, sans-serif;
  font-size: 54px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 1.2;
  margin: 0 0 10px;
  max-width: 450px;
  padding: 45px 0 0;
  text-align: left;
}

h3 {
  color: #5D5966;
  font-family: Rubik, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
  padding: 0;
  text-align: left;
}

h4.application-list {
  color: #5D5966;
  font-family: Rubik, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
  padding: 0;
  text-align: left;
}


b {
  color: #2E2D33;
  font-family: Rubik, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
}

div.controls {
  align-items: flex-start;
  color: #2E2D33;
  display: flex;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  line-height: 27.2px;
  margin-top: 25px;
  text-align: center;
}

div.col1 {
  color: #2E2D33;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  line-height: 27.2px;
  text-align: center;
}


a.signup {
  background-color: #69559E;
  border: 2px solid #514677;
  border-image: none 100% 1 0 stretch;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px 0;
  box-sizing: border-box;
  color: #FFFFFF;
  display: inline-block;
  font-family: "Exo 2", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 28.8px;
  padding: 14px 26px;
  text-align: center;
  text-decoration: none currentcolor solid;
  text-decoration-thickness: auto;
  text-transform: uppercase;
  transition: background-color .3s ease-out, border .3s ease-out;
  }

a.signup:hover {
  background-color: #F98102;
}

a.signup:active {
  background-color: #D66F02;
  border-color: #A35502;
}

a.customers {
  color: #6A5CA2;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 60px;
  margin-left: 15px;
  text-align: center;
  text-decoration: none currentcolor solid;
  text-decoration-thickness: auto;
  transition: all .2s;
}

i.fa.fa-arrow-right {
  -moz-osx-font-smoothing: grayscale;
  color: #6A5CA2;
  display: inline-block;
  font-family: FontAwesome;
  font-feature-settings: normal;
  font-kerning: auto;
  font-language-override: normal;
  font-optical-sizing: auto;
  font-size: 12px;
  font-size-adjust: none;
  font-variant-alternates: normal;
  font-variant-position: normal;
  font-variation-settings: normal;
  font-weight: 400;
  line-height: 1;
  margin-left: 1px;
  opacity: .9;
  text-align: center;
  text-rendering: auto;
  transform: translateX(2px);
  transition: all .3s;
  vertical-align: 1px;
}

i.fa.fa-arrow-right:before {
  content: "";
}

div.graphic-container {
  color: #2E2D33;
  flex: 0 0 50%;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  height: 100%;
  line-height: 27.2px;
  margin: 30px 0 0;
  position: relative;
  text-align: center;
}

div.graphic {
  background-attachment: scroll;
  background-clip: border-box;
  background-color: transparent;
  background-image: url(https://istackspub.s3.amazonaws.com/mindpool/MacbookPro.png);
  background-origin: padding-box;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 1005px 935px;
  color: #2E2D33;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  height: 400px;
  line-height: 27.2px;
  position: relative;
  text-align: center;
  top: 13px;
  width: 785px;
}

div.wallpaper {
  background-attachment: scroll;
  background-clip: border-box;
  background-color: transparent;
  background-image: linear-gradient(to top,#FAE3CF, #C9C4DD);
  background-origin: padding-box;
  background-position: 0 0;
  background-repeat: repeat;
  background-size: auto;
  color: #2E2D33;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  height: 360px;
  left: 105px;
  line-height: 27.2px;
  position: absolute;
  text-align: center;
  top: 29px;
  width: 577px;
}

div.browser {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.04) 0 1px 2px, rgba(0, 0, 0, 0.03) 0 2px 3px, rgba(0, 0, 0, 0.02) 0 3px 4px;
  color: #2E2D33;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  left: 123px;
  line-height: 27.2px;
  position: absolute;
  text-align: center;
  top: 46px;
  width: 540px;
}

div.bar {
  align-items: center;
  background-attachment: scroll;
  background-clip: border-box;
  background-color: transparent;
  background-image: linear-gradient(0deg,#DCDBDE 0,#E7E6E9 100%);
  background-origin: padding-box;
  background-position: 0 0;
  background-repeat: repeat;
  background-size: auto;
  border-bottom: 1px solid #CFCED2;
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;
  color: #2E2D33;
  display: flex;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  height: 22px;
  justify-content: flex-start;
  line-height: 27.2px;
  text-align: center;
  width: 100%;
}

img {
  border-radius: 0 0 5px 5px;
  box-sizing: border-box;
  color: #2E2D33;
  cursor: default;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  line-height: 27.2px;
  max-width: 100%;
  text-align: center;
  vertical-align: middle;
}



/* End CSS Custom Hero */

/* Features */

div.features {
  color: #2E2D33;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  line-height: 27.2px;
}

h4.opening {
  color: #5D5966;
  font-family: Rubik, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.8;
  margin: 60px auto 0;
  max-width: 670px;
  padding: 0 15px;
  text-align: center;
}

.hr-home {
  border: 0 none #C7C6CC;
  border-image: none 100% 1 0 stretch;
  border-top: 1px solid #C7C6CC;
  color: #808080;
  cursor: default;
  display: block;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  height: 1px;
  line-height: 27.2px;
  margin: 75px auto;
  max-width: 800px;
  padding: 0;
}

/* End of Features */


/* App Dashboard */

div.org {
  background-color: #EFEFF1;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.075) 0 0 2px inset,rgba(255, 255, 255, 0.8) 0 1px 1px;
  box-sizing: border-box;
  color: #2E2D33;
  font-family: Rubik,sans-serif;
  line-height: 22.85px;
  margin-top: 20px;
  overflow: hidden;
}

div.org:first-child {
  margin-top: 0;
}

div.header {
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 3px 3px 0 0;
  box-shadow: rgba(0, 0, 0, 0.03) 0 1px 0, rgba(0, 0, 0, 0.03) 0 1px 2px;
  box-sizing: border-box;
  color: #2E2D33;
  display: flex;
  font-family: Rubik, sans-serif;
  justify-content: space-between;
  line-height: 22.85px;
  margin: 1px;
  padding: 13px 15px 15px;
}

div.info {
  box-sizing: border-box;
  color: #2E2D33;
  flex-grow: 1;
  font-family: Rubik, sans-serif;
  line-height: 22.85px;
  margin-right: 15px;
  overflow: hidden;
}

ul {
  box-sizing: border-box;
  color: #2E2D33;
  font-family: Rubik, sans-serif;
  line-height: 22.85px;
  list-style: none;
  margin: 0;
  padding: 15px;
}

li.project {
  box-sizing: border-box;
  color: #2E2D33;
  font-family: Rubik, sans-serif;
  line-height: 22.85px;
  margin-bottom: 15px;
  text-align: left;
}

li.project:last-child {
  margin-bottom: 0;
}

div.AppListPanel {
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 2px 4px;
  box-sizing: border-box;
  color: #2E2D33;
  display: flex;
  font-family: Rubik, sans-serif;
  line-height: 22.85px;
  padding: 13px 15px;
  text-align: left;
}


div.icon {
  box-sizing: border-box;
  color: #2E2D33;
  font-family: Rubik, sans-serif;
  line-height: 22.85px;
  text-align: left;
}

div.content {
  align-items: center;
  box-sizing: border-box;
  color: #2E2D33;
  display: flex;
  flex-grow: 1;
  font-family: Rubik, sans-serif;
  justify-content: space-between;
  line-height: 22.85px;
  margin-left: 10px;
  overflow: hidden;
  text-align: left;
}

/* div.col1 {
  box-sizing: border-box;
  color: #2E2D33;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: Rubik, sans-serif;
  line-height: 22.85px;
  margin-right: 15px;
  overflow: hidden;
  text-align: left;
} */

div.col2 {
  box-sizing: border-box;
  color: #2E2D33;
  flex-shrink: 0;
  font-family: Rubik, sans-serif;
  line-height: 22.85px;
  text-align: right;
}


.tools-header {
  box-sizing: border-box;
  color: #908C99;
  font-family: "Exo 2",sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.1;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 2px;
  text-transform: uppercase;
}

.section-header {
  box-sizing: border-box;
  color: #908C99;
  font-family: "Exo 2",sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.1;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 2px;
  text-transform: uppercase;
}

a.LoaderButton.btn.btn-warning {
  background-color: initial;
  background-image: none;
  border: 1px solid #FD9527;
  border-image: none 100% 1 0 1 0 stretch;
  border-radius: 4px;
  box-sizing: border-box;
  color: #DA7204;
  cursor: pointer;
  display: inline-block;
  font-family: Rubik,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857;
  margin-bottom: 0;
  padding: 6px 11px 5px;
  text-align: center;
  text-decoration: none currentcolor solid;
  text-decoration-thickness: auto;
  touch-action: manipulation;
  transition: all .3s;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

a.LoaderButton.btn.btn-warning:active {
  background-color: #EC971F;
  border-color: #AB5800;
  box-shadow: rgba(0, 0, 0, 0.125) 0 3px 5px inset;
  color: #FFFFFF;
  outline: 0 none currentcolor;
}

a.LoaderButton.btn.btn-warning:hover {
  background-color: #FFAA50;
  border-color: #D58512;
  color: #FFFFFF;
  outline: 0 none currentcolor;
}

a.LoaderButton.btn.btn-warning:focus {
  background-color: #EC971F;
  border-color: #985F0D;
  color: #FFFFFF;
  outline: 0 none currentcolor;
  outline-offset: -2px;
}

a.LoaderButton.btn.btn-warning:not(.btn-link) {
  border-radius: 3px;
  transition: background-color .25s ease-out, border .25s ease-out;
}


.app-header-subtitle {
  text-transform: lowercase;
  text-transform: capitalize;
  color: #6a5ca2;
}

.mid-stream-text-lowercase {
  text-transform: lowercase;
}


/* End of App Dashboard */


