.Settings .lander {
    padding: 30px 0;
    text-align: center;
  }


.Settings .lander h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}
  
.Settings .lander p {
    color: #999;
}

.btn-msteams {
    background-color: #363562;
    color: white;
}

th {
    font-size: 14px;
    text-align: center;
}

td {
    font-size: 14px;
    text-align: center;
    
}

#link-button {
    margin-right: 1px;
}

.search-bar {
    margin-top: 8px;
}

.search-date {
    font-size: 10px;
}

.react-bootstrap-table-pagination-total {
    margin-left: 5px;
    font-size: 14px;
}

#pageDropDown {
    font-size: 14px;
}

.dropdown-toggle {
    font-size: 14px;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #363562;
    border-color: #363562;
}

li.page-item {
    color: #363562;
    font-size: 14px;
}

li.page-link:hover{
    color: #363562;
    font-size: 14px;
}

a.page-link:hover {
    color: #363562;
}

div.org {
    background-color: #EFEFF1;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.075) 0 0 2px inset,rgba(255, 255, 255, 0.8) 0 1px 1px;
    box-sizing: border-box;
    color: #2E2D33;
    font-family: Rubik,sans-serif;
    line-height: 22.85px;
    margin-top: 20px;
    overflow: hidden;
  }
  
  div.org:first-child {
    margin-top: 0;
  }


  div.header {
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 3px 3px 0 0;
    box-shadow: rgba(0, 0, 0, 0.03) 0 1px 0, rgba(0, 0, 0, 0.03) 0 1px 2px;
    box-sizing: border-box;
    color: #2E2D33;
    display: flex;
    font-family: Rubik, sans-serif;
    justify-content: space-between;
    line-height: 22.85px;
    margin: 1px;
    padding: 13px 15px 15px;
  }
  
  div.info {
    box-sizing: border-box;
    color: #2E2D33;
    flex-grow: 1;
    font-family: Rubik, sans-serif;
    line-height: 22.85px;
    margin-right: 15px;
    overflow: hidden;
  }

  div.AppListPanel-settings {
    align-items: center;
    background-color: #EFEFF1;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.05) 0 2px 4px;
    box-sizing: border-box;
    color: #2E2D33;
    display: flex;
    font-family: Rubik, sans-serif;
    line-height: 22.85px;
    padding: 13px 15px;
    text-align: left;
  }


.settings-header {
    box-sizing: border-box;
    color: #908C99;
    font-family: "Exo 2",sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1.1;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 2px;
    text-transform: uppercase;
}

