.Video .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Video .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }

  Video.lander-content {
    color: #2E2D33;
    font-family: Rubik,sans-serif;
    font-size: 17px;
    line-height: 27.2px;
  }

  #player {
    width: 480px;
    height: 480px;
  }

  #forced-space {
    padding: 12px;
  }

  #bullet-for-video {
    font-size: 200%; 
    vertical-align: middle;
  }


  .video-sections {
    box-sizing: border-box;
    color: #908C99;
    font-family: "Exo 2",sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1.1;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 2px;
    text-transform: uppercase;
  }
