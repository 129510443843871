.nav-brand-text {
    color: #363562
}

.nav-brand-text-beta {
    font-size: 12px;
    color: #333;
}

.navbar-light .navbar-nav .nav-link {
    font-size: inherit;
    margin-top: 11px;
}

.navbar-brand {
    font-size: 1.15rem;
    line-height: inherit;
    white-space: nowrap;
}


.login {
    background-color: #EFEEF0;
    border: 2px solid #D4D3D8;
    border-image: none 100% 1 0 stretch;
    border-radius: 3px;
    color: #5D5966;
    cursor: pointer;
    display: inline-block;
    font-family: "Exo 2",sans-serif;
    font-size: 14px;
    line-height: 1;
    padding: 10px 17px 11px;
    text-decoration: none currentcolor solid;
    text-decoration-thickness: auto;
    transition: all .2s;
  }
  
 .login:hover {
    background-color: #E7E6E9;
  }
  
 .login:active {
    background-color: #E1E1E4;
    border-color: #C7C6CC;
  }

  .logout {
    background-color: #EFEEF0;
    border: 2px solid #D4D3D8;
    border-image: none 100% 1 0 stretch;
    border-radius: 3px;
    color: #5D5966;
    cursor: pointer;
    display: inline-block;
    font-family: "Exo 2",sans-serif;
    font-size: 14px;
    line-height: 1;
    padding: 10px 17px 11px;
    text-decoration: none currentcolor solid;
    text-decoration-thickness: auto;
    transition: all .2s;
  }
  
 .logout:hover {
    background-color: #E7E6E9;
  }
  
 .logout:active {
    background-color: #E1E1E4;
    border-color: #C7C6CC;
  }

  .navlink {
    border-radius: 3px;
    color: #6A5CA2;
    cursor: pointer;
    display: inline-block;
    font-family: "Exo 2",sans-serif;
    font-size: 14px;
    line-height: 1;
    padding: 10px 17px 11px;
    text-decoration: none currentcolor solid;
    text-decoration-thickness: auto;
    transition: all .2s;
  }


