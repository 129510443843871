.Bookmarks .lander {
    padding: 30px 0;
    text-align: center;
  }


.Bookmarks .lander h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    text-align: center;
}
  
.Bookmarks .lander p {
    color: #999;
}

.btn-msteams {
    background-color: #363562;
    color: white;
}

th {
    font-size: 14px;
    text-align: center;
}

td {
    font-size: 14px;
    text-align: center;
    
}

#link-button {
    margin-right: 1px;
}

.search-bar {
    margin-top: 8px;
}

.search-date {
    font-size: 10px;
}

.react-bootstrap-table-pagination-total {
    margin-left: 5px;
    font-size: 14px;
}

#pageDropDown {
    font-size: 14px;
}

.dropdown-toggle {
    font-size: 14px;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #363562;
    border-color: #363562;
}

li.page-item {
    color: #363562;
    font-size: 14px;
}

li.page-link:hover{
    color: #363562;
    font-size: 14px;
}

a.page-link:hover {
    color: #363562;
}